import { change, Field, formValueSelector, InjectedFormProps, isDirty, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import { Fragment, PureComponent } from 'react';
import moment from 'moment';

import { ACCOUNT_STATUSES, CLOSED, ON_HOLD, STREET, SUSPENDED } from 'src/common/constants';
import { AccountStatusDropdown } from 'src/common/components';
import { AccountStatusToggle, AccountStatusToggleIcon } from '../styled';
import {
  ActionButtonTooltip,
  Checkbox,
  DatePicker,
  Input,
  LocationPicker,
  TextArea,
  TypedField,
  UpdateTrackerRouteSwitch,
} from 'src/core/components';
import { AppState } from 'src/store';
import { automaticAccountNumberGenerationSelector } from 'src/vendors/ducks/features';
import { BulkyPickupByLocation } from 'src/customers/interfaces/Customers';
import {
  Button,
  ButtonSet,
  DetailsListActionButton,
  Grid,
  GridColumn,
  ModalFixedFooter,
  ModalFixedHeader,
  ModalTitle,
  Text,
} from 'src/core/components/styled';
import { checkLocationHasScheduledDailyRoutes } from 'src/routes/services/routeTemplate';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { Customer, CustomerLocationAddress } from 'src/customers/interfaces/Customers';
import { FormContainer } from 'src/core/components/styled/Form';
import { FutureAccountStatusDropdown, LocationAlertsMultiSelect } from '..';
import {
  dateFormat,
  hasCity,
  hasCoordinates,
  hasCountry,
  hasStreet,
  hasStreetNumber,
  hasZip,
  isDateValidValidator,
  isOptionalPhone,
  isRequired,
  maxLength50,
  maxLength500,
} from 'src/utils/services/validator';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';
import { DuckFunction } from 'src/contracts/ducks';
import { getFutureAccountStatusCanceledMessagge } from 'src/customers/constants/customers';
import { humanizeDate } from 'src/utils/services/formatter';
import { isAdminSelector } from 'src/account/ducks';
import { isBulkyItemSchedulerFeatureEnabled } from 'src/vendors/ducks/features';
import { loadBulkyPickUpByLocation, resetBulkyPickUp } from 'src/customers/ducks/locations';
import { PinOnMapContainer } from '../styled/CustomerLocations';
import { SelectOnMap } from 'src/routes/components/styled';
import { TemporaryAddressModal } from '../modals';
import { TODAY } from 'src/core/constants/weekdays';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import confirm from 'src/core/services/confirm';
import customerLocationEditorFormInitialValues from 'src/customers/services/customerLocationsEditorFormInitialValuesSelector';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import NotificationPhoneNumbersModal from '../modals/NotificationPhoneNumbersModal';
import translate from 'src/core/services/translate';

interface ComponentProps {
  locationId?: number;
  serviceContractFullAddress: CustomerLocationAddress;
  serviceContractLocation: string;
}

interface PropsWithoutReduxForm extends ComponentProps {
  accountStatusId?: number;
  allowResetPickupsPerLocation: boolean;
  bulkyPickupByLocation: BulkyPickupByLocation;
  change: any;
  closeModal: (pristine: boolean) => void;
  customer?: Customer;
  futureAccountStatusFromDate?: string;
  futureAccountStatusId: number;
  futureAccountStatusToDate?: string;
  isAdmin: boolean;
  isAutomaticAccountNumberGenerationFeatureActive?: boolean;
  isBulkyItemSchedulerEnabled: boolean;
  isEsriRecord?: boolean;
  isFormDirty: boolean;
  isLockedAddress?: boolean;
  isTemporaryAddress?: boolean;
  loadBulkyPickUpByLocation: DuckFunction<typeof loadBulkyPickUpByLocation>;
  onAddressChange: (address: CustomerLocationAddress) => void;
  onPinLocationClick: () => void;
  resetBulkyPickUp: DuckFunction<typeof resetBulkyPickUp>;
  vendorId: number;
}

interface State {
  accountStatusChanged: boolean;
  hasScheduledDailyRoutes: boolean;
  isNotificationPhoneNumberModalOpen: boolean;
  isTemporaryAddressModalOpen: boolean;
  isUpdateAccountStatusSectionOpen: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class CustomerLocationEditorForm extends PureComponent<Props, State> {
  readonly state = {
    accountStatusChanged: false,
    hasScheduledDailyRoutes: false,
    isNotificationPhoneNumberModalOpen: false,
    isTemporaryAddressModalOpen: false,
    isUpdateAccountStatusSectionOpen: false,
  };

  componentDidMount() {
    const { locationId, vendorId } = this.props;

    if (locationId) {
      checkLocationHasScheduledDailyRoutes(locationId, vendorId).then(response => {
        this.setState({ hasScheduledDailyRoutes: response });
      });
    }
  }

  onAddressChange = debounce((event, address) => {
    const { latitude, longitude } = address;
    this.props.change('temporaryAddress', address.formattedAddress);
    this.props.change('address', address);
    if (latitude && longitude) {
      this.props.onAddressChange(address);
    }
  }, 800);

  accountStatusChange = () => {
    const { change, futureAccountStatusId, futureAccountStatusFromDate } = this.props;

    this.setState({ accountStatusChanged: (!!futureAccountStatusId && !!futureAccountStatusFromDate) || false }, () => {
      change('futureAccountStatus.accountStatusId', null);
      change('futureAccountStatus.fromDate', null);
    });
  };

  closeTemporaryAddressModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.setState({ isTemporaryAddressModalOpen: false });
  };

  openTemporaryAddressModal = (event: any) => {
    event.stopPropagation();
    this.setState({ isTemporaryAddressModalOpen: true });
  };

  openNotificationPhoneNumbersModal = (event: any) => {
    event.stopPropagation();
    this.setState({ isNotificationPhoneNumberModalOpen: true });
  };

  closeNotificationPhoneNumbersModal = async (formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }

    this.setState({ isNotificationPhoneNumberModalOpen: false });
  };

  saveTextPhoneNumbers = (notificationPhoneNumbers: string) => {
    this.props.change('textNotificationPhones', notificationPhoneNumbers);
  };

  toggle = () => {
    this.setState(prevState => ({
      isUpdateAccountStatusSectionOpen: !prevState.isUpdateAccountStatusSectionOpen,
    }));
  };

  onResetBulkyPickUp = () => {
    const { loadBulkyPickUpByLocation, locationId, resetBulkyPickUp } = this.props;
    locationId &&
      resetBulkyPickUp(locationId).then(() => {
        loadBulkyPickUpByLocation(locationId);
      });
  };

  render() {
    const {
      accountStatusId,
      allowResetPickupsPerLocation,
      bulkyPickupByLocation,
      closeModal,
      customer,
      futureAccountStatusFromDate,
      futureAccountStatusId,
      futureAccountStatusToDate,
      handleSubmit,
      isAdmin,
      isAutomaticAccountNumberGenerationFeatureActive,
      isBulkyItemSchedulerEnabled,
      isEsriRecord,
      isFormDirty,
      isLockedAddress,
      isTemporaryAddress,
      locationId,
      onPinLocationClick,
      pristine,
    } = this.props;
    const { isUpdateAccountStatusSectionOpen, hasScheduledDailyRoutes, accountStatusChanged } = this.state;

    const vendorLocationAlertEnabled = customer ? customer.vendorLocationAlertEnabled : undefined;
    const customerAccountStatusTypeId = customer ? customer.customerAccountStatusTypeId : undefined;

    const accountStatusMessageFuture =
      futureAccountStatusId && futureAccountStatusFromDate && moment(futureAccountStatusFromDate).toDate() > TODAY
        ? 'customers.futureAccountStatus'
        : undefined;

    const accountStatusMessageCurrent =
      futureAccountStatusFromDate &&
      moment(futureAccountStatusFromDate).toDate() <= TODAY &&
      futureAccountStatusToDate &&
      moment(futureAccountStatusToDate).toDate() >= TODAY
        ? 'customers.currentAccountStatus'
        : undefined;

    const renderFormFields = (isMobile: boolean) =>
      customer?.customerTypeId !== STREET ? (
        <Grid padding="no sMedium" multiLine>
          <GridColumn size="12/12">
            <Field
              name="locationName"
              component={Input}
              label={translate('customers.locationName')}
              validate={[isRequired]}
              disabled={isEsriRecord}
            />
          </GridColumn>
          {isTemporaryAddress && (
            <GridColumn size="12/12">
              <Field
                name="temporaryAddress"
                component={Input}
                label={translate('customers.locationName')}
                validate={[isRequired]}
                disabled
              />
            </GridColumn>
          )}
          {!isTemporaryAddress && (
            <>
              <GridColumn size={!isMobile ? '11/12' : '10/12'}>
                <TypedField
                  name="address"
                  component={LocationPicker}
                  validate={[isRequired, hasCoordinates, hasCountry, hasCity, hasZip, hasStreet, hasStreetNumber]}
                  onChange={this.onAddressChange}
                  props={{
                    label: translate('common.address'),
                    disabled: isTemporaryAddress || isLockedAddress || isEsriRecord,
                  }}
                />
              </GridColumn>
              <PinOnMapContainer size="1/12">
                <SelectOnMap
                  onClick={e => {
                    e.preventDefault();
                    onPinLocationClick();
                  }}
                >
                  <ActionButtonTooltip icon="pinOnMap" tooltip="pinLocationOnMap" size="medium" />
                </SelectOnMap>
              </PinOnMapContainer>
            </>
          )}

          <GridColumn size={!isMobile ? '12/12' : '10/12'} padding="small xSmall">
            <Field
              name="isTemporaryAddress"
              component={Checkbox}
              size="small"
              label={translate('customers.temporaryAddress')}
              disabled={isLockedAddress}
            />
            {isTemporaryAddress ? (
              <Button
                type="button"
                text
                margin="no small"
                color="primary"
                padding="no"
                disabled={isLockedAddress || !isTemporaryAddress}
                onClick={this.openTemporaryAddressModal}
              >
                <ActionButtonTooltip icon="edit" tooltip="edit" />
              </Button>
            ) : (
              <div style={{ width: '50px', height: '14px', display: 'inline-block' }} />
            )}

            {isAdmin && (
              <Field
                name="isLockedAddress"
                component={Checkbox}
                margin="no medium"
                size="small"
                disabled={isLockedAddress || !isTemporaryAddress}
                label={translate('customers.lockedAddress')}
              />
            )}
          </GridColumn>
          <GridColumn size="12/12">
            <Field name="email" component={Input} label={translate('common.email')} disabled={isEsriRecord} />
          </GridColumn>
          <GridColumn size="12/12">
            <Field
              name="phone"
              component={Input}
              label={translate('common.mainPhone')}
              validate={[isOptionalPhone]}
              disabled={isEsriRecord}
            />
          </GridColumn>
          <GridColumn size={isMobile ? '10/12' : '11/12'}>
            <Field
              name="textNotificationPhones"
              disabled
              component={Input}
              label={translate('common.notificationPhoneNumbers')}
            />
          </GridColumn>
          <GridColumn size="1/12">
            <DetailsListActionButton margin="no xSmall" onClick={this.openNotificationPhoneNumbersModal}>
              <ActionButtonTooltip icon="edit" tooltip="edit" />
            </DetailsListActionButton>
          </GridColumn>
          <GridColumn size="12/12">
            <Field
              name="accountNumber"
              component={Input}
              label={translate('customers.accountNumber')}
              validate={[maxLength50]}
              disabled={isEsriRecord || isAutomaticAccountNumberGenerationFeatureActive}
            />
          </GridColumn>
          {vendorLocationAlertEnabled && (
            <GridColumn size="12/12">
              <Field
                name="locationAlerts"
                component={LocationAlertsMultiSelect}
                label={translate('customers.locationAlerts')}
              />
            </GridColumn>
          )}

          <GridColumn size="12/12">
            <Field
              name="note"
              component={TextArea}
              rows="5"
              scrollYVisible
              validate={[maxLength500]}
              maxLength={500}
              label={translate('customers.locationNotes')}
            />
          </GridColumn>
          <GridColumn size="6/12">
            <Field
              name="accountStatusId"
              component={AccountStatusDropdown}
              label={translate('common.accountStatus')}
              dropdownProps={{
                disabled:
                  customerAccountStatusTypeId === ON_HOLD ||
                  customerAccountStatusTypeId === SUSPENDED ||
                  customerAccountStatusTypeId === CLOSED,
                menuPosition: 'fixed',
              }}
              onChange={this.accountStatusChange}
            />
          </GridColumn>
          {!(
            customerAccountStatusTypeId === ON_HOLD ||
            customerAccountStatusTypeId === SUSPENDED ||
            customerAccountStatusTypeId === CLOSED
          ) && (
            <GridColumn size="5/12">
              {!isUpdateAccountStatusSectionOpen && accountStatusChanged && getFutureAccountStatusCanceledMessagge()}
              {!isUpdateAccountStatusSectionOpen &&
                (accountStatusMessageFuture || accountStatusMessageCurrent) &&
                !accountStatusChanged && (
                  <Text block margin="small no no no">
                    {translate(accountStatusMessageFuture || accountStatusMessageCurrent, {
                      label: ACCOUNT_STATUSES[futureAccountStatusId!].name,
                      date: humanizeDate(
                        accountStatusMessageFuture ? futureAccountStatusFromDate : futureAccountStatusToDate,
                      ),
                    })}
                  </Text>
                )}
            </GridColumn>
          )}
          {(customerAccountStatusTypeId === ON_HOLD ||
            customerAccountStatusTypeId === SUSPENDED ||
            customerAccountStatusTypeId === CLOSED) && (
            <GridColumn size="5/12">
              <Text block margin="small no no no">
                {translate(
                  customerAccountStatusTypeId === ON_HOLD
                    ? 'customers.locationCustomerLevelDisabledForOnHold'
                    : 'customers.locationCustomerLevelDisabledForSuspendedOrClosed',
                  {
                    label: ACCOUNT_STATUSES[customerAccountStatusTypeId].name,
                  },
                )}
              </Text>
            </GridColumn>
          )}
          <GridColumn size="1/12">
            {locationId && (
              <AccountStatusToggle
                onClick={this.toggle}
                disabled={
                  customerAccountStatusTypeId === ON_HOLD ||
                  customerAccountStatusTypeId === SUSPENDED ||
                  customerAccountStatusTypeId === CLOSED
                }
              >
                <AccountStatusToggleIcon isOpen={isUpdateAccountStatusSectionOpen} />
              </AccountStatusToggle>
            )}
          </GridColumn>
          <GridColumn size="12/12">
            {isUpdateAccountStatusSectionOpen && (
              <Fragment>
                <Grid padding="no" margin="small no" multiLine>
                  <GridColumn size="4/12" padding="no">
                    <Field
                      name="futureAccountStatus.accountStatusId"
                      component={FutureAccountStatusDropdown}
                      margin="no"
                      accountStatusId={accountStatusId}
                      label={translate('common.updateStatusTo')}
                      validate={[isRequired]}
                    />
                  </GridColumn>
                </Grid>
                <Grid padding="no" margin="small no" multiLine>
                  <GridColumn size="4/12" padding="no">
                    <Field
                      name="futureAccountStatus.fromDate"
                      component={DatePicker}
                      tabletAlignLeft
                      disabledDays={[
                        {
                          before: moment().add(1, 'days').toDate(),
                          after: futureAccountStatusToDate
                            ? moment(futureAccountStatusToDate).subtract(1, 'days').toDate()
                            : undefined,
                        },
                      ]}
                      validate={[isRequired, isDateValidValidator]}
                      margin="no"
                      label={translate('common.from')}
                      isClearable
                      props={{ name: 'futureAccountStatus.fromDate' }}
                    />
                  </GridColumn>
                  {(futureAccountStatusId === SUSPENDED || futureAccountStatusId === ON_HOLD) && (
                    <GridColumn size="4/12">
                      <Field
                        name="futureAccountStatus.toDate"
                        component={DatePicker}
                        tabletAlignHalfLeft
                        disabledDays={[
                          {
                            before: futureAccountStatusFromDate
                              ? moment(futureAccountStatusFromDate).add(1, 'days').toDate()
                              : moment().add(2, 'days').toDate(),
                            after: undefined,
                          },
                        ]}
                        margin="no"
                        label={translate('common.to')}
                        isClearable
                        props={{ name: 'futureAccountStatus.toDate' }}
                        validate={[isDateValidValidator]}
                      />
                    </GridColumn>
                  )}
                </Grid>
              </Fragment>
            )}
          </GridColumn>
        </Grid>
      ) : (
        <Grid padding="no small no no" multiLine>
          <GridColumn size="12/12">
            <Field
              name="locationName"
              component={Input}
              label={translate('customers.locationName')}
              validate={[isRequired]}
            />
          </GridColumn>
          {isTemporaryAddress && (
            <GridColumn size="12/12">
              <Field
                name="temporaryAddress"
                component={Input}
                label={translate('customers.locationName')}
                validate={[isRequired]}
                disabled
              />
            </GridColumn>
          )}
          {!isTemporaryAddress && (
            <>
              <GridColumn size="11/12">
                <TypedField
                  name="address"
                  component={LocationPicker}
                  validate={[isRequired, hasCoordinates, hasCountry, hasCity, hasZip, hasStreet, hasStreetNumber]}
                  onChange={this.onAddressChange}
                  props={{
                    label: translate('common.address'),
                    disabled: isTemporaryAddress || isLockedAddress,
                  }}
                />
              </GridColumn>
              <PinOnMapContainer size="1/12">
                <SelectOnMap
                  onClick={e => {
                    e.preventDefault();
                    onPinLocationClick();
                  }}
                >
                  <ActionButtonTooltip icon="pinOnMap" tooltip="pinLocationOnMap" size="medium" />
                </SelectOnMap>
              </PinOnMapContainer>
            </>
          )}
          <GridColumn size={isMobile ? '10/12' : '12/12'} padding="small xSmall">
            <Field
              name="isTemporaryAddress"
              component={Checkbox}
              size="small"
              label={translate('customers.temporaryAddress')}
              disabled={isLockedAddress}
            />
            {isTemporaryAddress ? (
              <Button
                type="button"
                text
                margin="no small"
                color="primary"
                padding="no"
                disabled={isLockedAddress || !isTemporaryAddress}
                onClick={this.openTemporaryAddressModal}
              >
                <ActionButtonTooltip icon="edit" tooltip="edit" />
              </Button>
            ) : (
              <div style={{ width: '50px', height: '14px', display: 'inline-block' }} />
            )}

            {isAdmin && (
              <Field
                name="isLockedAddress"
                component={Checkbox}
                margin="no medium"
                size="small"
                disabled={isLockedAddress || !isTemporaryAddress}
                label={translate('customers.lockedAddress')}
              />
            )}
          </GridColumn>
          {vendorLocationAlertEnabled && (
            <GridColumn size="12/12">
              <Field
                name="locationAlerts"
                component={LocationAlertsMultiSelect}
                label={translate('customers.locationAlerts')}
              />
            </GridColumn>
          )}
          <GridColumn size="12/12">
            <Field
              name="note"
              component={TextArea}
              rows="5"
              scrollYVisible
              validate={[maxLength500]}
              maxLength={500}
              label={translate('customers.locationNotes')}
            />
          </GridColumn>
        </Grid>
      );

    const { lastBulkyPickupResetDate, scheduledBulkyPickupsSinceLastReset } = bulkyPickupByLocation;
    const lastBulkyResetDate = lastBulkyPickupResetDate ? moment(lastBulkyPickupResetDate).format(dateFormat) : '-';

    return (
      <FormContainer>
        <form onSubmit={handleSubmit}>
          <DesktopWidthView>
            <ModalFixedHeader padding="sMedium no no no">
              <ModalTitle> {translate(`customers.${locationId ? 'edit' : 'create'}Location`)}</ModalTitle>
            </ModalFixedHeader>
          </DesktopWidthView>
          <UseIsMobileWidthView render={renderFormFields} />

          {isBulkyItemSchedulerEnabled && allowResetPickupsPerLocation && locationId && (
            <GridColumn size="12/12" padding="sMedium small sMedium small" verticalAlign="center">
              <Button type="button" margin="no no no small" color="primary" onClick={this.onResetBulkyPickUp} noWrap>
                {translate('vendors.bulkyItemScheduler.resetPickups')}
              </Button>

              <Text block margin="no no no small">
                {translate('vendors.bulkyItemScheduler.scheduledPickUps', {
                  scheduledPickUps: scheduledBulkyPickupsSinceLastReset,
                })}
                <br />
                {translate('vendors.bulkyItemScheduler.scheduledPickUpsDate', {
                  lastBulkyResetDate,
                })}
              </Text>
            </GridColumn>
          )}

          <ModalFixedFooter padding="small" justifyContent="space-around" isShadowed>
            {customer?.customerTypeId !== STREET && locationId && (
              <UpdateTrackerRouteSwitch
                isFormDirty={isFormDirty}
                hasScheduledDailyRoutes={hasScheduledDailyRoutes}
                wrapperMargin="no small no no"
                topMargin="no"
              />
            )}
            <ButtonSet margin="no">
              <Button type="submit" color="primary">
                {translate('common.save')}
              </Button>
              <Button type="button" margin="no no no small" color="secondary" onClick={() => closeModal(pristine)}>
                {translate('common.cancel')}
              </Button>
            </ButtonSet>
          </ModalFixedFooter>
          {this.state.isTemporaryAddressModalOpen && (
            <TemporaryAddressModal
              closePopup={this.closeTemporaryAddressModal}
              locationId={locationId!}
              changeLocationAddress={this.onAddressChange}
            />
          )}
          {this.state.isNotificationPhoneNumberModalOpen && (
            <NotificationPhoneNumbersModal
              onCancel={this.closeNotificationPhoneNumbersModal}
              saveTextPhoneNumbers={this.saveTextPhoneNumbers}
            />
          )}
        </form>
      </FormContainer>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const { locationId, serviceContractLocation, serviceContractFullAddress } = ownProps;
  const initialValues = customerLocationEditorFormInitialValues(
    state.customers.locations,
    locationId,
    serviceContractLocation,
    serviceContractFullAddress,
  );

  const formSelector = formValueSelector('customerLocationEditor');

  return {
    accountStatusId: formSelector(state, 'accountStatusId'),
    allowResetPickupsPerLocation: state.vendors.bulkyItemScheduler?.bulkyItemScheduler?.allowResetPickupsPerLocation,
    bulkyPickupByLocation: state.customers.locations.bulkyPickupByLocation,
    customer: state.customers.customer.customer,
    futureAccountStatusFromDate: formSelector(state, 'futureAccountStatus.fromDate'),
    futureAccountStatusId: formSelector(state, 'futureAccountStatus.accountStatusId'),
    futureAccountStatusToDate: formSelector(state, 'futureAccountStatus.toDate'),
    initialValues,
    isAdmin: isAdminSelector(state.account.login),
    isAutomaticAccountNumberGenerationFeatureActive: automaticAccountNumberGenerationSelector(
      state.vendors.features.features,
    ),
    isBulkyItemSchedulerEnabled: isBulkyItemSchedulerFeatureEnabled(state),
    isEsriRecord: initialValues.isEsriRecord,
    isFormDirty: isDirty('customerLocationEditor')(state),
    isLockedAddress: initialValues.isLockedAddress,
    isTemporaryAddress: formSelector(state, 'isTemporaryAddress'),
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor),
  };
};

const mapDispatchToProps = {
  change,
  loadBulkyPickUpByLocation,
  resetBulkyPickUp,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<any, PropsWithoutReduxForm>({
    form: 'customerLocationEditor',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(CustomerLocationEditorForm),
);
