import { combineReducers } from 'redux';

import { reducer as adhocServiceHistoryReducer } from './adhocServiceHistory';
import { reducer as contactLocationsReducer } from './contactLocations';
import { reducer as contactsReducer } from './contacts';
import { reducer as containerListReducer } from './containerList';
import { reducer as customerReducer } from './customer';
import { reducer as customerImportReducer } from './customerImport';
import { reducer as customerImportStatusTypeReducer } from './customerImportStatusType';
import { reducer as customerPortalReducer } from './customerPortal';
import { reducer as customerTypesOptions } from './customerTypesOptions';
import { reducer as customersReducer } from './customers';
import { reducer as deleteCollectionPointsReducer } from './deleteCollectionPoints';
import { reducer as locationReducer } from './location';
import { reducer as locationAlertsReducer } from './locationAlerts';
import { reducer as locationServiceTypesReducer } from './locationServiceTypes';
import { reducer as customerLocationsReducer } from './locations';
import { reducer as mapControlReducer } from './mapControl';
import { reducer as customerPaymentMethodsReducer } from './paymentMethods';
import { reducer as paymentTermOptionsReducer } from './paymentTermOptions';
import { reducer as rateBuilderReducer } from './rateBuilder';
import { reducer as routeRecommendations } from './routeRecommendations';
import { reducer as routeTemplatesByScheduledDaysReducer } from './routeTemplatesByScheduledDays';
import { reducer as rubiconServicesReducer } from './rubiconServices';
import { reducer as rubiconServicesDocumentsReducer } from './rubiconServicesDocuments';
import { reducer as rubiconServicesOptionsReducer } from './rubiconServicesOptions';
import { reducer as rubiconServicesRatesReducer } from './rubiconServicesRates';
import { reducer as serviceReducer } from './service';
import { reducer as serviceHistoryReducer } from './serviceHistory';
import { reducer as servicesReducer } from './services';
import { reducer as streetNetworkReducer } from './streetNetwork';
import { reducer as streetNetworkServiceAreaReducer } from './streetNetworkServiceAreas';
import { reducer as suspendedLocationsReducer } from './suspendedLocations';
import { reducer as temporaryAddressReducer } from './temporaryAddress';
import { reducer as upcomingServicesReducer } from './upcomingServices';
import { reducer as vehiclesInProximity } from './vehiclesInProximity';
import { reducer as currencyOptions } from './currencyOptions';
import { reducer as streetNetworkDataLayerReducer } from './streetNetworkDataLayer';

export { loadAdhocServiceHistory } from './adhocServiceHistory';
export { resetContactLocations, saveAssignedLocations } from './contactLocations';
export {
  COMPLETE_DELETE as COMPLETE_DELETE_CUSTOMER_CONTACT,
  COMPLETE_LOAD as COMPLETE_LOAD_CUSTOMER_CONTACT,
  COMPLETE_SAVE as COMPLETE_SAVE_CUSTOMER_CONTACT,
  contactByIdSelector,
  deleteContact,
  loadContacts,
  saveContact,
  resetContacts,
} from './contacts';
export {
  COMPLETE_LOAD as COMPLETE_LOAD_CUSTOMER,
  COMPLETE_SAVE as COMPLETE_SAVE_CUSTOMER,
  loadCustomer,
  resetCustomer,
  saveCustomer,
  setCurrentCustomersPageUrl,
} from './customer';
export { loadCustomerImportStatusTypeValues } from './customerImportStatusType';
export { loadCustomerTypes } from './customerTypesOptions';
export {
  COMPLETE_DELETE as COMPLETE_DELETE_CUSTOMER,
  COMPLETE_LOAD as COMPLETE_LOAD_CUSTOMERS,
  customerLocationsSelector,
  deleteCustomer,
  loadCustomersSimplified,
  resetCustomers,
} from './customers';
export { deleteCollectionPoints } from './deleteCollectionPoints';
export { loadLocation, resetLocation } from './location';
export { loadLocationAlerts, resetLocationAlerts } from './locationAlerts';
export { loadRouteTemplatesByScheduledDays, resetRouteTemplatesByScheduledDays } from './routeTemplatesByScheduledDays';
export { loadRubiconServicesOptions } from './rubiconServicesOptions';
export {
  loadService,
  resetService,
  saveServiceWorkflow,
  serviceDetailsSelector,
  loadServiceWorkOrders,
} from './service';
export {
  exportServiceHistory,
  loadSearchedLocation,
  loadServiceHistory,
  resetSearchedLocation,
  resetServiceHistory,
  setCurrentServiceHistoryPageUrl,
} from './serviceHistory';
export {
  COMPLETE_DELETE as COMPLETE_DELETE_SERVICE,
  COMPLETE_SAVE as COMPLETE_SAVE_SERVICE,
  deleteService,
  loadServices,
  loadYServices,
  resetServices,
  resetServicesForServiceContractWorkflow,
  saveService,
  serviceTypeIdSelector,
} from './services';
export {
  ADD_SUSPENDED_LOCATION,
  COMPLETE_LOAD as COMPLETE_LOAD_SUSPENDED_LOCATIONS,
  COMPLETE_SAVE as COMPLETE_SAVE_SUSPENDED_LOCATIONS,
  EDIT_SUSPENDED_LOCATION,
  editSuspendedLocation,
  exportSuspendedLocations,
  loadSuspendedLocations,
  resetSuspendedLocations,
  saveSuspended,
  suspendLocation,
} from './suspendedLocations';
export { completeLoad, resetTemporaryAddress, saveTemporaryAddress } from './temporaryAddress';

export {
  loadStreetNetwork,
  loadStreetNetworkSegmentDetails,
  loadStreetNetworkSegmentDetailsForDailyRoute,
  resetDashboardStreetNetwork,
  resetSnowOrSweeperStreetNetwork,
  setCheckedDashboardStreetNetwork,
  setIsDrawSelectionModeOn,
  setIsEditGeoFencesModeOn,
  setIsEditRouteGeoFenceModeOn,
  setIsEditSegmentsModeOn,
  setIsLegendCollapsed,
  setSegmentColoringType,
  setStreetNetworkMapViewport,
} from './streetNetwork';

export {
  clearStreetNetworkServiceAreasSelectedFeature,
  loadStreetNetworkCustomerLocations,
  loadStreetNetworkHaulerLocations,
  loadStreetNetworkServiceAreas,
  resetNetworkServiceAreaCustomerLocations,
  resetNetworkServiceAreaHaulerLocations,
  resetStreetNetworkServiceAreas,
  saveStreetNetworkServiceAreas,
  setSelectedHaulerLocationsForDisplay,
  setShowCustomerLocations,
} from './streetNetworkServiceAreas';

export {
  COMPLETE_DELETE as COMPLETE_DELETE_LOCATION,
  COMPLETE_SAVE as COMPLETE_SAVE_LOCATION,
  SEARCH_LOCATIONS,
  customerLocationByIdSelector,
  deleteCustomerLocation,
  loadCustomerLocations,
  loadCustomerLocationsForWorkflow,
  resetCustomerLocations,
  reverseGeocode,
  saveCustomerLocation,
  searchLocations,
} from './locations';

export { loadContainerList } from './containerList';
export { loadServiceTypesForLocation, resetServiceTypesForLocation } from './locationServiceTypes';
export { getVehiclesInProximity, resetVehiclesInProximity } from './vehiclesInProximity';

export {
  downloadCustomersImportFile,
  loadCustomersImportUploadedFilesStatus,
  resetCustomerImport,
  uploadCustomersImportFile,
} from './customerImport';

export { loadPaymentTerms } from './paymentTermOptions';

export { deleteUpcomingService, loadUpcomingServices, resetUpcomingServices } from './upcomingServices';

export { loadCurrencies } from './currencyOptions';

export { loadCustomerPortalRoles } from './customerPortal';
export { loadStreetNetworkDataLayers, clearStreetNetworkDataLayers } from './streetNetworkDataLayer';

export const reducer = combineReducers({
  adhocServiceHistory: adhocServiceHistoryReducer,
  contactLocations: contactLocationsReducer,
  contacts: contactsReducer,
  containerList: containerListReducer,
  currencies: currencyOptions,
  customer: customerReducer,
  customerImport: customerImportReducer,
  customerImportStatusType: customerImportStatusTypeReducer,
  customerPortal: customerPortalReducer,
  customers: customersReducer,
  customerTypes: customerTypesOptions,
  deleteCollectionPoints: deleteCollectionPointsReducer,
  location: locationReducer,
  locationAlerts: locationAlertsReducer,
  locations: customerLocationsReducer,
  locationServiceTypes: locationServiceTypesReducer,
  mapControl: mapControlReducer,
  paymentMethods: customerPaymentMethodsReducer,
  paymentTerms: paymentTermOptionsReducer,
  rateBuilder: rateBuilderReducer,
  routeRecommendations,
  routeTemplatesByScheduledDays: routeTemplatesByScheduledDaysReducer,
  rubiconServiceRates: rubiconServicesRatesReducer,
  rubiconServices: rubiconServicesReducer,
  rubiconServicesDocuments: rubiconServicesDocumentsReducer,
  rubiconTypes: rubiconServicesOptionsReducer,
  service: serviceReducer,
  serviceHistory: serviceHistoryReducer,
  services: servicesReducer,
  streetNetwork: streetNetworkReducer,
  streetNetworkServiceAreas: streetNetworkServiceAreaReducer,
  streetNetworkDataLayer: streetNetworkDataLayerReducer,
  suspendedLocations: suspendedLocationsReducer,
  temporaryAddress: temporaryAddressReducer,
  upcomingServices: upcomingServicesReducer,
  vehiclesInProximity,
});
